import React, {Suspense} from "react";
import {Redirect, Route, Router, Switch} from "react-router-dom";
import {createBrowserHistory} from "history";
import {pathHome} from "./utils/constants";
import Home from "./screen/Home";
import ScrollToTop from "./snippet/ScrollToTop";

const history = createBrowserHistory();

function App() {
    return (
        <React.StrictMode>
            <Suspense fallback={<></>}>
                <Router history={history}>
                    <ScrollToTop/>
                    <Switch>
                        <Route exact path={pathHome} component={Home}/>
                        <Route component={() => <Redirect to={pathHome}/>}/>
                    </Switch>
                </Router>
            </Suspense>
        </React.StrictMode>
    );
}

export default App;
